<template>
    <v-row class="fill-height">
        <v-col>
            <v-row>
                <v-col md="12">
                    <v-card>
                        <v-card-title>
                            <v-icon class="mr-1">mdi-shopping</v-icon>
                            Produtos
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col md="12">
                    <v-row justify="center">
                        <v-col md="10">
                            <v-data-table 
                                :headers="headers" 
                                item-key="id"
                                class="elevation-1"
                                :items="product"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                :to="{
                                                    name: 'product',
                                                    params: { id: item.id }
                                                }"
                                            >
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'products',
    props: {
        source: String
    },
    data: () => ({
        headers: [
            {text: "Nome", value: "name", align: 'center'},
            {text: "CNPJ", value: "cnpj", align: 'center'},
            {text: "Telefone", value: "phone", align: 'center'},
            {text: "Representante", value: "representative", align: 'center'},
            {text: "Observação", value: "obs", align: 'center'},
            {text: "Endereço", value: "address", align: 'center'},
            {text: "Visualizar", value: "actions", sortable: false, align: 'center'}
        ],
        product: [
            {
                id: 1,
                name: 'fornecedor teste',
                cnpj: '62.744.868/0001-53',
                phone: '(41) 98505-0603',
                representative: 'fornecedor representante',
                obs: 'obs',
                address: 'endereço qualquer',
            },
            {
                id: 2,
                name: 'fornecedor teste 2',
                cnpj: '62.744.868/0001-53',
                phone: '(41) 98505-0603',
                representative: 'fornecedor representante',
                obs: 'obs',
                address: 'endereço qualquer',
            }
        ]
    }),
    methods: {
        pushRouteToView(route) {
            this.$router.push({ name: route })
        },
    }

}
</script>